@import url("global.css");

.about-wrapper {
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
}

.about-image-wrapper {
    margin-left: 4rem;
}

.about-image-inner-wrapper {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 3rem auto 0 auto;
    left: -1rem;

}

.about-image {
    width: 100%;
    position: relative;
    z-index: 2;
    border-radius: 0.5rem;
    left: -0.5rem;
    top: -0.5rem;
    transition: 0.3s;
}

.about-image-box {
    border-radius: 0.5rem;
    border: 2px solid var(--primary-color);
    position: absolute;
    width: calc(100% + 3.5px);
    height: 100%;
    left: 0.5rem;
    top: 0.5rem;
    transition: 0.3s;
}

.about-image-inner-wrapper:hover .about-image-box {
    left: -2px;
    top: -2px;
    background: var(--primary-color);
}
.about-image-inner-wrapper:hover .about-image {
    left: 0;
    top: 0;
}

.about-list-wrapper li::before {
    content: "•";
    color: var(--primary-color); margin-right: 10px;
}

.about-list-wrapper li {
    display: inline-block;
    width: 33%;
}

@media screen and (min-width: 768px) {

    .about-wrapper {
        margin: 0 auto;
    }

    .about-inner-wrapper {
        display: flex;
        align-items: top;
        justify-content: space-between;
    }

    .about-list-wrapper li {
        display: inline-block;
        width: 50%;
    }

    .about-image-inner-wrapper {
        width: 250px;
        margin: 1rem 0 0 0;
        left: 0;
    
    }
    
}

@media screen and (min-width: 992px) {

    .about-image-inner-wrapper {
        width: 300px;    
    }
    
}