@import url('stylesheets/global.css');

html {
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background: var(--primary-color-dark);
  color: var(--text-color);
  font-size: 16px;
  position: relative;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0,0,0,0)
}

::-webkit-scrollbar-thumb {
  background: var(--text-color);
  border-radius: 5px;
  transition: 0.3s;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.container {
  position: relative;
  max-width: 1200px;
  width: calc(100% - 1rem);
  margin: 0 auto;
  padding: 0 1rem;
}

.container-mid {
  max-width: 992px;
}

.container-wide {
  max-width: 100%;
}

.container-flat {
  padding-top: 0;
  padding-bottom: 0;
}

.flex {
  display: flex;
}

.flex-justify {
  justify-content: space-between;
}

.flex-center {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.whole-page {
  width: 100%;
  height: 100vh;
}

.appear-child {
  opacity: 0;
  transform: translateY(10px);
  transition: 0.3s ease-out;
}

.section-wrapper {
  padding: 4rem 0 4rem 0;
}

#contact {
  padding: 2rem 0 0rem 0;
}

.appear {
  opacity: 1;
  transform: translateY(0);
}

@import url('stylesheets/components.css');
@import url('stylesheets/navigation.css');
@import url('stylesheets/footer.css');
@import url('stylesheets/hero.css');
@import url('stylesheets/helix.css');
@import url('stylesheets/about.css');
@import url('stylesheets/websites.css');
@import url('stylesheets/project.css');
@import url('stylesheets/contact.css');

@media screen and (min-width: 768px) {

  body {
    font-size: 18px;
    line-height: 1.3;
  }

  .container {
    width: calc(100% - 10rem);
  }

  .container-wide {
    max-width: 100%;
    width: calc(100% - 1rem);
  }

  #contact {
    padding: 2rem 0 10rem 0;
  }

}
@media screen and (min-width: 1200px) {
  .container {
    width: calc(100% - 14rem);
  }

  .container-wide {
    max-width: 100%;
    width: calc(100% - 3rem);
  }

  .section-wrapper {
    padding: 4rem 0 14rem 0;
  }

}