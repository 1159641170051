@import url('global.css');

.hero-content {
    text-align: left;
    font-size: 1rem;
    z-index: 2;
}

.hero-outer-wrapper {
    width: 100%;
    flex-direction: row;
    gap: 8rem;
}

h1, h1 span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: 0.05em;
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin: 1.5rem 0;
}

h1 span {
    color: var(--text-color-dark);
    font-size: 2.5rem;
}

.hero-content h4 {
    font-weight: 400;
    color: var(--primary-color)
}

.hero-content p {
    max-width: 650px;
    line-height: 1.8rem;
    margin-bottom: 3rem;
}

.link-underline {
    position: relative;
    color: var(--primary-color);
}

.link-underline::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--primary-color);
    transition: width 0.3s ease-in-out;
}
  
.link-underline:hover::before {
    width: 100%;
}
  
  .link-underline:hover::after {
    width: 0;
}

#BioHelix {
    display: none;
}

@media screen and (min-width: 768px) {

    .hero-content {
        font-size: 1.25rem;
    }

    h1, h1 span {
        letter-spacing: 0.05em;
        font-size: 3rem;
        line-height: 3rem;
        margin: 2rem 0;
    }

    h1 span {
        color: var(--text-color-dark);
        font-size: 2.5rem;
    }
}

@media screen and (min-width: 1200px) {
    #BioHelix {
        display: block;
    }

    h1, h1 span {
        letter-spacing: 0.05em;
        font-size: 4rem;
        line-height: 3.5rem;
        margin: 2rem 0;
    }

    h1 span {
        color: var(--text-color-dark);
        font-size: 3.5rem;
    }
}