@import url('global.css');


.helix {
    transform: rotateZ(0deg);
    margin-top: 1.8rem;
}

.strand {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin: 0.58em auto;
    --d: calc(var(--i) / var(--total));
    --hue: calc(var(--primary-hue) - 25 + var(--i) * 5);
    --color: hsl(var(--hue), var(--primary-saturation), 50%, 1);
    --duration: 1.5s;
    --delay: calc((var(--duration) * -1) + 0.1s * var(--i));
    animation: var(--duration) cubic-bezier(0.4, 0, 0.6, 1) infinite;
    animation-delay: var(--delay);
  }

.left, .right {
    animation: inherit;
    display: grid;
    grid-template-areas: "layer";
    align-items: center;
    width: 2em;
}
.left > *, .right > * {
    grid-area: layer;
}
.left {
    transform: translateZ(0px);
}
.right {
    transform: scaleX(-1);
}
.dot {
    animation: inherit;
    animation-duration: calc(var(--duration) * 2);
    width: 100%;
    animation-name: move-dot;
    --scale-start: 0.5;
    --scale-end: 1;
}
@keyframes move-dot {
    50% {
        transform: translateX(200%);
   }
}
.right .dot {
    --scale-start: 1;
    --scale-end: 0.5;
}
.dot::before {
    display: block;
    content: "";
    border-radius: 50%;
    width: 0.5em;
    height: 0.5em;
    background: var(--color);
    animation: inherit;
    animation-name: scale-dot;
}
@keyframes scale-dot {
    33% {
        transform: scale(var(--scale-start));
   }
    66% {
        transform: scale(var(--scale-end));
   }
}
.line {
    width: calc(100% - 1em);
    height: 0.125em;
    border-radius: 1em;
    background: var(--color);
    margin-left: auto;
    transform-origin: center right;
    animation: inherit;
    animation-name: scale-line;
}
@keyframes scale-line {
    50% {
        transform: scaleX(0.1);
   }
}
