.website-grid {
    padding: 2rem 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.website-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0rem;
}

.align-right .website-content {
    align-items: flex-end;
}

.website-tag {
    padding: 0;
    margin: 0;
    line-height: 1.4rem;
    color: var(--primary-color);
    font-weight: 400;
    transition: 0.5s ease-out;
}

/* .website-content:hover .website-tag {
    color: var(--secondary-color);
} */

.website-title {
    padding: 0;
    margin: 0;
    font-size: 2rem;
    font-weight: 700;
    color: var(--text-color);
}

.website-image {
    position: relative;
    margin: 1rem 0 0 0;
    width: 100%;
}

.website-image img {
    width: 100%;
    border-radius: 0.5rem;
    transition: 0.3s;
    margin-bottom: -6px;
}

.website-description {
    padding: 1.5rem;
    border-radius: 1rem;
    background: var(--primary-color-mid-dark);
}

.website-links {
    margin-top: 1rem;
    font-size: 1.5rem;
}

.website-links a {
    transition: 0.5s ease-out;
}

.website-links a:hover {
    color: white;
}

@media screen and (min-width: 1200px) {

    .website-grid {
        gap: 10rem;
    }

    .website-image {
        position: absolute;
        right: 0;
        margin: -4rem 0 0 0;
        width: 60%;
        padding: 0;
        border-radius: 1rem;
        border: 2px solid var(--primary-color-dark);
        z-index: 1;
        transition: 0.5s ease-out;
    }

    .align-right .website-image {
        left: 0;
        right: auto;
    }

    .website-image img {
        border-radius: 1rem;
    }

    .website-image:hover {
        padding: 1rem;
        border: 2px solid var(--primary-color);
    }

    .website-description {
        position: relative;
        z-index: 2;
        width: 45%;
    }
    
    .website-content .tech-stack {
        position: relative;
        max-width: 40%;
    }
    
    .align-right .website-content .tech-stack {
        max-width: 35%;
    }
}