@import url('global.css');

/* Button */
.button {
    color: var(--primary-color);
    background: transparent;
    border: 1px solid var(--primary-color);
    border-radius: 5px;
    transition: 0.3s;
    cursor: pointer;
}

/* Full width button */
.button.full-width {
    width: 100%;
}
  
.button:hover {
    background: var(--primary-color-transparent)
}

/* Section Title */
.title-wrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 1rem;
}

.title-number {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--primary-color);
    margin-right: 1rem;
}

.title-text {
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
    white-space: nowrap;
}

.title-line {
    max-width: 20rem;
    width: 100%;
    height: 1px;
    background: var(--primary-color);
    margin-left: 2rem;
}

@media screen and (max-width: 768px) {
    button {
        font-size: 1rem!important;
        padding: 1rem 2rem!important;
    }
}