@import url('global.css');

.contact-wrapper {
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}

.contact-form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
  
.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form input[type="tel"],
.contact-form textarea {
  padding: 1rem;
  border-radius: 0.25rem;
  border: 2px solid var(--primary-color-dark); 
  font-size: 1rem;
}

/* Change border color on input focus */
input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
textarea:focus {
  outline: none; /* Removes the default focus outline */
  border-color: var(--primary-color); /* Pink border for focused input */
}

/* CSS styles */
.checkbox-group {
  display: flex;
  gap: 2rem;
  align-items: center;
}

/* Style for checkboxes */
.checkbox-group input[type="checkbox"] {
  appearance: none; /* Remove default checkbox appearance */
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  border: 2px solid var(--primary-color);
  transition: background-color 0.2s;
}

.checkbox-group input[type="checkbox"]:checked {
  background-color: var(--primary-color); /* Background color when checkbox is checked */
}

.checkbox-group input[type="checkbox"]:checked {
  background-color: var(--primary-color); /* Background color when checkbox is checked */
}

.contact-form textarea {
    resize: vertical; /* Allows the user to resize the textarea vertically */
}
  
.contact-form label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

/* Style for the submit button */
.submit-button {
  padding: 1rem 2rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: var(--primary-color-dark); /* Darker shade on hover */
}