@import url("global.css");

.project-wrapper {
    position: relative;
    z-index: 10;
}

.project-grid {
    padding: 2rem 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;
}
  
.project-box {
    position: relative;
    padding: 2rem;
    top: 0;
    bottom: 0;
    color: var(--text-color);
    background: var(--primary-color-mid-dark);
    transition: background 0.3s ease, top 0.3s ease;/* Include padding in the transition */
    border-radius: 1rem;
    overflow: hidden; /* To ensure the pseudo-element border doesn't overflow outside the border-radius */
}

.project-box:hover {
    background-color: var(--primary-color-transparent);
    top: -0.5rem;
}

.icon-links {
    position: absolute;
    left: 1.5rem;
    right: 1.5rem;
    display: flex;
    justify-content: space-between;
}
  
.links-right {
    right: 0;
}

.icon {
    font-size: 2.5em;
    padding: 0.5rem;
    transition: color 0.3s ease;
}

.links-right .icon {
    color: var(--text-color);
}

.links-right .icon:hover {
    color: white;
}

.lightbulb-icon {
    color: var(--primary-color)!important;
}

.project-content {
    padding-top: 2rem;
    /* Style for content */
}
  
.project-title {
    color: white;
}
  
.tech-stack {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem; /* Adjust the gap as necessary */
    align-items: center; /* This will vertically center the dots with the text */
    color: white;
}
  
.tech-stack-item:not(:last-child)::after {
    content: ' · '; /* Centered dot (middot) with spaces on either side */
    text-align: center;
    margin-left: 0.5rem; /* Adjust the margin as necessary */
}
  
  /* Responsive grid columns */
 @media (min-width: 768px) {
    .project-grid {
        grid-template-columns: repeat(1, 1fr); /* 2 columns for tablets */
    }
    
    .project-content {
        padding-top: 3rem;
    }
  
    .project-description {
        padding: 0.5rem 0;
    }
}
  
@media (min-width: 992px) {
    .project-grid {
         grid-template-columns: repeat(2, 1fr); /* 3 columns for desktop */
    }
    
    .project-content {
        padding-top: 4rem;
    }
  
    .project-description {
        padding: 1rem 0;
    }
}
  
@media (min-width: 1200px) {
    .project-grid {
         grid-template-columns: repeat(3, 1fr); /* 3 columns for desktop */
    }
}