@import url('global.css');

.outer-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    transition: transform 0.3s;
    background: var(--primary-color-dark);
    box-shadow: 0px 2px 4px var(--primary-color-dark);
    padding: 1rem 0;
}

.slide-up {
  transform: translateY(-100%);
}

/* Logo */
.logo-container {
    display: inline-block;
}
  
.logo-container:hover  .hexagon-background path{
    fill: rgba(255, 243, 251, 0.2);
    transition: 0.2s;
}
  
.hexagon-background path {
    transition: 0.2s;
}


/* General Navigation */
.navigation-item {
    display: flex;
    align-items: center;
    font-family: 'Roboto Mono', monospace;
}
  
.navigation-number {
    color: var(--primary-color); /* Replace with the desired color */
    margin-right: 4px;
}
  
.navigation-link div {
    text-decoration: none;
    transition: 0.3s;
}
  
.navigation-link:hover div {
    color: var(--primary-color);
}

.hide-navigation {
    display: none!important;
}

/* Desktop Navigation */

.desktop-navigation .navigation-link div {
    padding: 1rem 2rem;
}

.desktop-navigation .navigation-item {
    height: 65px;
}

.desktop-navigation .button-wrapper {
    padding: 13px 0;
}


/* Mobile Navigation */
.mobile-navigation {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 500px;
    height: 100vh;
    background: var(--primary-color-mid-dark);
    transform: translateX(100%);
    transition: 0.5s;
    z-index: 1;
}
.mobile-navigation.open {
    transform: translateX(0%);
}

.mobile-navigation .navigation-link div {
    padding: 1rem 2rem;
}

.mobile-navigation .button {
    margin-top: 1rem;
}

.toggle-wrapper {
    position: relative;
    z-index: 2;
}

.toggle {
    height: 60px;
    width: 60px;
    padding: 10px;
    cursor: pointer;
}

.toggle .bar {
    height: 2px;
    width: 100%;
    background: var(--primary-color);
    margin-bottom: 10px;
    transition: 0.3s;
}

.toggle .bar:nth-child(1) {
    margin-top: 6px;
}

.toggle .bar:nth-child(2) {
    width: 75%;
    margin-left: 25%;
}

.toggle .bar:nth-child(3) {
    width: 50%;
    margin-left: 50%;
}

.toggle.open .bar:nth-child(1) {
    transform: rotate(45deg) translate(8px, 1px);
}

.toggle.open .bar:nth-child(2) {
    width: 100%;
    transform: rotate(-45deg) translate(8px, 1px);
    margin-left: 0%;
}

.toggle.open .bar:nth-child(3) {
    width: 0;
}

@media screen and (min-width: 992px) {

}