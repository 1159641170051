:root {
    --primary-color: rgb(234, 132, 255);
    --primary-color-transparent: rgba(234, 132, 255, 0.2);
    --primary-color-dark: rgb(17, 1, 20);
    --primary-color-mid-dark: rgb(40, 6, 46);
    --text-color: rgb(210, 203, 212);
    --text-color-dark: rgb(165, 153, 172);

    --primary-hue: 290;
    --primary-saturation: 48.2%;

    /* Gold color */
    --secondary-color: rgb(255, 223, 0); /* Bright gold */
    --secondary-color-muted: rgba(255, 223, 0, 0.2); /* Muted gold */
    --secondary-color-dark: rgb(128, 114, 0); /* Darker shade of gold */
}