@import url('global.css');


.socials-desktop-wrapper {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
    display: none;
}

.socials-section {
    height: 350px;
    width: 40px;
    display: flex;
    flex-direction: column;
}
  
.rotation-wrapper-outer {
    display: table;
}
  
.rotation-wrapper-inner {
    padding: 50% 0;
    height: 0;
}
  
.element-to-rotate {
    transform-origin: top left;
    transform: rotate(90deg) translate(-100%);
    margin-top: 50%;
    white-space: nowrap;
    padding-bottom: 50;
}

.hover-and-left {
    position: relative;
    margin-left: 5px;
}

.hover-and-left a {
    position: relative;
    left: 0px;
    transition: 0.3s;
}

.hover-and-left a:hover {
    color: var(--primary-color);
    left: -5px;
}

.hover-and-up a {
    position: relative;
    top: 0px;
    margin-top: 5px;
    transition: 0.3s;
}
  
.hover-and-up a:hover {
    top: -5px;
}

.hover-and-up:hover .social-icon {
    stroke: var(--primary-color);
}

.hover-and-up:hover a {
    color: var(--primary-color);
}

.hover-and-up a {
    transition: 0.3s;
}

.social-icon {
    transition: 0.3s;
}
  
.element-to-rotate div {
    top: -30px;
}
  
.element-to-rotate div a {
    padding: 10px;
}
  
.up-bar-wrapper {
    margin-top: 20px;
    flex-grow: 1;
    position: relative;
    padding: 20px;
}
  
.up-bar {
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    width: 2px;
    background: var(--text-color);
}

.social-media-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
}

.social-media-list li a {
    display: inline-block;
    padding: 10px;
}

.social-media-list li:last-child a {
    margin-bottom: -10px;
}

.socials-mobile-wrapper {
    margin-top: 5rem;
}

.socials-mobile-wrapper .social-media-list {
    flex-direction: row;
    justify-content: center;
}

.socials-mobile-wrapper  .social-media-list li:last-child a {
    margin-bottom: 0;
}

.socials-mobile-wrapper .email-wrapper {
    text-align: center;
    margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
    .socials-desktop-wrapper {
        display: block;
    }
    .socials-mobile-wrapper {
        display: none;
    }
}